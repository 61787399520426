import { useEffect, useState, useLayoutEffect, useRef } from "react";
import "../SingleProduct/SingleProduct.css";
import Navbar from "../NavBar/Navbar";
import UserFooter from "../UserFooter";
import { toast } from "react-toastify";
import Search from "../Search/Search";
import { useDispatch } from "react-redux";
import {
  addToWishlist,
  checkWishList,
  getSingleRetailProducts,
  postSingleRetailCart,
  removeFromWishlist,
  viewAllRetailCart,
} from "../Features/action/index";
import { useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Button, Modal } from "react-bootstrap";
import UserLogin from "../UserLogin/Login";
import SocialShare from "../../RetailerWholeSaler/SocialShare";
import "./style.css";
import ReactImageMagnify from "react-image-magnify";
import Spinner from "../../Admin/Spinner";
import { RatingView } from "react-simple-star-rating";
import moment from "moment";
import { Helmet } from "react-helmet";

const SingleProductDetail = (props) => {
  document.title = "Kreesa | Product page";
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const { singleRetailProducts, wishlist, loader } = useSelector(
    (state) => state.userProducts
  );

  const [selected, setSelected] = useState();
  const [countdown, setCountdown] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [productName, setProductName] = useState([]);
  const [productRating, setProductRating] = useState([]);
  const [colorName, setColorName] = useState([]);
  const [count, setCount] = useState(1);

  const [customOrder, setCustomOrder] = useState();

  //set value as we want
  const onChange = (e) => {
    setCount(e.target.value);
  };

  // Create handleIncrement event handler
  const handleIncrement = () => {
    if (count < selected?.stock) {
      setCount(count + 1);
    } else {
      toast.info("Maximum Quantity reached");
    }
  };

  //Create handleDecrement event handler
  const handleDecrement = (e) => {
    setCount(1);
    if (count <= selected?.stock) {
      setCount(count === 1 ? 1 : count - 1);
    } else {
      toast.info("Minimum Quantity reached");
    }
  };

  useEffect(() => {
    dispatch(getSingleRetailProducts(id));
    if (
      location?.state?.prevPath === "/custom-order" &&
      JSON.parse(localStorage.getItem("customOrder")) !== null
    ) {
      setCustomOrder(JSON.parse(localStorage.getItem("customOrder")));
      dispatch(viewAllRetailCart(customOrder?.userId));
    } else {
      dispatch(viewAllRetailCart(localStorage.getItem("userId")));
    }
  }, []);

  //   set default image
  const state = [];
  singleRetailProducts?.specification?.map((p, i) => {
    p.image?.map((r) => {
      state.push({
        image: `${AxiosConfig.defaults.baseURL}/kreesa/image/product/${r.id}/${r.imageName}`,
      });
    });
  });
  const [selectImage, setSelectImage] = useState([]);

  useLayoutEffect(() => {
    setSelectImage(state[0]?.image);
  }, [singleRetailProducts]);

  useEffect(() => {
    if (singleRetailProducts) {
      let stars = 0;
      let totalReview = 0;
      singleRetailProducts.review?.forEach((el) => {
        stars = stars + el.totalStar;
        totalReview = totalReview + 1;
      });
      setProductRating({ star: stars, totalUserCount: totalReview });
    }
  }, [singleRetailProducts]);

  useEffect(() => {
    if (singleRetailProducts.specification) {
      setProductName(singleRetailProducts?.specification[0]?.id);
      setSelected({
        name: singleRetailProducts?.productName,
        rate: singleRetailProducts?.specification[0]?.productPrice,
        discount: singleRetailProducts?.specification[0]?.productDiscount,
        colorId: singleRetailProducts?.specification[0]?.color[0]?.id,
        stock: singleRetailProducts?.specification[0]?.stock,
        specificationId: singleRetailProducts?.specification[0]?.id,
        image: singleRetailProducts?.specification[0]?.image[0]?.imageName,
        imageId: singleRetailProducts?.specification[0]?.image[0]?.id,
      });
    } else {
      dispatch(getSingleRetailProducts(id));
    }
    dispatch(
      checkWishList({
        userId: +localStorage.getItem("userId"),
        productId: id,
      })
    );
  }, [singleRetailProducts]);

  useEffect(() => {
    const interval = setInterval(() => {
      let time = moment.duration(
        moment(singleRetailProducts?.toDate).diff(moment()) - 1000
      );
      if (time.milliseconds() <= 0) {
        setCountdown(null);
      } else {
        setCountdown(time);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [singleRetailProducts]);
  // ${AxiosConfig.defaults.baseURL}/kreesa/image/product/${r.id}/${r.imageName}
  const imageUrl = singleRetailProducts?.specification?.[0]?.image?.[0]?.imageName
    ? `${AxiosConfig.defaults.baseURL}/kreesa/image/product/${singleRetailProducts.specification[0].id}/${singleRetailProducts.specification[0].image[0].imageName}`
    : null;

  return (
    <div className="">
      <Helmet>
        <meta property="og:title" content={singleRetailProducts?.productName} />
        <meta property="og:description" content={singleRetailProducts?.productDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="product" />
      </Helmet>
      {singleRetailProducts?.flashSale && countdown !== null && (
        <div className="flash-sale-box">
          Flash Sale Ends in :{" "}
          {`${countdown?.days()} Days   ${countdown?.hours()} Hours   ${countdown?.minutes()} Minutes  ${countdown?.seconds()} Seconds`}
        </div>
      )}
      <Search />
      <Navbar />
      <div className="super_container">
        {/* main content start */}
        {!loader ? (
          <div className="single_products">
            <div
              className="container-fluid"
              style={{ backgroundColor: "#fff", padding: "11px" }}
            >
              <div className="row">
                <div className="col-lg-2 order-lg-1 order-2">
                  <ul className="image_lists image__list__horizon">
                    {singleRetailProducts?.specification
                      ?.filter((i) => i.id === selected?.specificationId)
                      ?.slice(0)
                      ?.reverse()
                      ?.map((i) =>
                        i.image?.map((r, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              setSelectImage(
                                `${AxiosConfig.defaults.baseURL}/kreesa/image/product/${r.id}/${r.imageName}`
                              )
                            }
                            data-image={imageUrl}
                          >
                            <img
                              src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${r.id}/${r.imageName}`}
                              alt={r.imageName}
                            />
                          </li>
                        ))
                      )}
                  </ul>
                </div>
                <div className="col-lg-4 order-lg-2 order-1">
                  <div className="image__perimeter">
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "kreesa",
                          isFluidWidth: true,
                          src: `${selectImage}`,
                          sizes:
                            "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                        },
                        largeImage: {
                          alt: "kreesa__large",
                          src: `${selectImage}`,
                          width: 1200,
                          height: 1800,
                        },
                        shouldHideHintAfterFirstActivation: false,
                        lensStyle: {
                          border: "solid 1px red",
                        },
                      }}
                      style={{ zIndex: "1" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 order-3">
                  <div className="product_description">
                    {/* <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Products</a>
                      </li>
                      <li className="breadcrumb-item active">Accessories</li>
                    </ol>
                  </nav> */}
                    <div className="product_name text-capitalize font-weight-bold">
                      {singleRetailProducts?.productName}
                    </div>
                    <div
                      className="product-rating mb-3 d-flex"
                      style={{ gap: "20px" }}
                    >
                      <RatingView
                        ratingValue={singleRetailProducts?.totalReview}
                        size={20}
                      />
                      <div className="rating-review">
                        {productRating?.star?.length
                          ? productRating?.star?.length
                          : "User"}{" "}
                        Reviews
                      </div>
                    </div>
                    <div>
                      {" "}
                      {selected?.discount === 0 ? (
                        <span className="product_price">
                          <b>Rs. {Math.round(selected?.rate)}</b> <span style={{color:"black", fontSize:18}}>{singleRetailProducts?.unit}</span>
                        </span>
                      ) : (
                        <p className="mt-1 font-weight-bold">
                          <strike>Rs. {Math.round(selected?.rate)}</strike>
                          <br />
                          <b className="text-success product_price">
                            {" "}
                            Rs.{" "}
                            {Math.round(
                              selected?.rate -
                                (selected?.rate * selected?.discount) / 100
                            )} <span style={{color:"black", fontSize:18}}>{singleRetailProducts?.unit}</span>
                          </b>
                          <strong className="text-danger font-weight-bold">
                            {" "}
                            - {selected?.discount} % OFF
                          </strong>
                        </p>
                      )}
                      <span style={{ color: "#e91e63" }}>
                        This price is inclusive of VAT as prescribed by Nepal
                        Government @13%
                      </span>
                    </div>
                    <div>
                      {" "}
                      {selected?.discount === 0 ? (
                        ""
                      ) : (
                        <>
                          <span className="product_saved">You Saved:</span>
                          <span
                            style={{ color: "black" }}
                            className="font-weight-bold"
                          >
                            {" "}
                            Rs.{" "}
                            {Math.round(
                              Math.abs(
                                selected?.rate -
                                  selected?.rate -
                                  (selected?.rate * selected?.discount) / 100
                              )
                            )}
                          </span>
                        </>
                      )}
                    </div>
                    <hr className="singleline" />
                    <div>
                      {" "}
                      <span className="product_info">
                        {singleRetailProducts?.isPerishable && (
                          <>
                            <div>
                              Perishable:{" "}
                              <span className="font-weight-bold">
                                {moment(singleRetailProducts?.expiryDate)
                                  .local()
                                  .format("ll")}
                              </span>
                            </div>
                            <div></div>
                          </>
                        )}
                        <div className="specs-items">
                          <span className="font-weight-bold">
                            {singleRetailProducts?.primaryCategoryName} /
                          </span>
                          <span className="font-weight-bold">
                            {singleRetailProducts?.secondaryCategoryName} /
                          </span>
                          <span className="font-weight-bold">
                            {singleRetailProducts?.ternaryCategoryName}
                          </span>
                        </div>
                      </span>
                      <br />{" "}
                      {singleRetailProducts.hasWarranty === true && (
                        <span className="product_info">
                          {singleRetailProducts.warrantyType}:{" "}
                          <span className="font-weight-bold">
                            {singleRetailProducts.warrantyPeriod}
                          </span>{" "}
                          warranty
                        </span>
                      )}
                      {/* <br />
                      <span className="product_info">
                        7 Days easy return policy
                      </span>
                      <br />{" "}
                      <span className="product_info">
                        In Stock: 25 units sold this week
                      </span>{" "} */}
                    </div>
                    <div>
                      <div className="row mt-1">
                        {selected?.discount === 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="col-md-5">
                              <div className="br-dashed">
                                <div className="row">
                                  <div className="col-md-3 col-xs-3">
                                    {" "}
                                    <img src="https://img.icons8.com/color/48/000000/price-tag.png" />{" "}
                                  </div>
                                  <div className="col-md-9 col-xs-9">
                                    <div className="pr-info">
                                      {" "}
                                      <span className="break-all">
                                        Get {selected?.discount}% instant
                                        discount + 10X rewards @ KREESA
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-7"> </div> */}
                          </>
                        )}
                      </div>
                      <div className="row mt-4">
                        <SocialShare
                          {...props}
                          url={window.location.href}
                          description={singleRetailProducts?.productName}
                          image={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${selected?.imageId}/${selected?.image}`}
                        />
                      </div>
                      {singleRetailProducts.hasVariant === true && (
                        <div className="row">
                          <h5 className="font-weight-bold mt-4 mb-2">
                            Available Variant
                          </h5>
                          <div className="text-capitalize d-flex flex-column">
                            {singleRetailProducts?.specification?.map(
                              (i, index) => (
                                <div
                                  className="form-check col-md-6 ml-2 d-flex mt-2"
                                  key={i?.id}
                                >
                                  <div>
                                    <input
                                      type="radio"
                                      defaultChecked={index === 0}
                                      value={i?.id}
                                      name="productName"
                                      id="productName"
                                      className="form-check-input"
                                      style={{
                                        width: 20,
                                        height: 20,
                                      }}
                                      onChange={(e) => {
                                        setSelectImage(
                                          `${AxiosConfig.defaults.baseURL}/kreesa/image/product/${i?.image[0]?.id}/${i?.image[0]?.imageName}`
                                        );
                                        setProductName(e.target.value);
                                        setSelected({
                                          ...selected,
                                          rate: i?.productRate,
                                          id: i?.id,
                                          colorId: i?.color[0]?.id,
                                          specificationId: i?.id,
                                          image: i?.image[0]?.imageName,
                                          stock: i?.stock,
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor="productName"
                                      className="form-check-label ml-2"
                                    >
                                      {i.variantName.charAt(0).toUpperCase() +
                                        i.variantName.slice(1)}
                                    </label>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                      <div className="row" style={{ marginTop: "15px" }}>
                        <div className="mt-1">
                          <h5 className="font-weight-bold mb-2 mt-4">
                            Available Colors{" "}
                          </h5>
                          {singleRetailProducts?.specification
                            ? singleRetailProducts?.specification
                                ?.filter((i) => i.id === parseInt(productName))
                                .map((i) => (
                                  <div
                                    className="col-md-12 ml-2 d-flex flex-row"
                                    style={{ gap: "10px" }}
                                  >
                                    {i?.color?.map((i, index) => (
                                      <div key={i.id}>
                                        <input
                                          type="radio"
                                          defaultChecked={index === 0}
                                          value={i?.colorName}
                                          name="colorName"
                                          className="form-check-input"
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginTop: 1,
                                          }}
                                          id="colorName"
                                          onChange={(e) => {
                                            setColorName(e.target.value);
                                            setSelected({
                                              ...selected,
                                              colorId: i?.id,
                                            });
                                          }}
                                        />
                                        <label
                                          htmlFor="colorName"
                                          className="form-check-label ml-2"
                                        >
                                          {i.colorName.charAt(0).toUpperCase() +
                                            i.colorName.slice(1)}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                ))
                            : null}
                        </div>
                        {/* <div className="col-xs-6" style={{ marginLeft: "15px" }}>
                        {" "}
                        <span className="product_options">RAM Options</span>
                        <br />{" "}
                        <button className="btn btn-primary btn-sm">
                          4 GB
                        </button>{" "}
                        <button className="btn btn-primary btn-sm">8 GB</button>{" "}
                        <button className="btn btn-primary btn-sm">16 GB</button>{" "}
                      </div>
                      <div className="col-xs-6" style={{ marginLeft: "55px" }}>
                        {" "}
                        <span className="product_options">Storage Options</span>
                        <br />{" "}
                        <button className="btn btn-primary btn-sm">
                          500 GB
                        </button>{" "}
                        <button className="btn btn-primary btn-sm">1 TB</button>{" "}
                      </div> */}
                      </div>
                    </div>
                    <hr className="singleline" />
                    <div className="order_info d-flex flex-row"></div>
                    <div className="row">
                      {singleRetailProducts?.expiryStatus === 0 && (
                        <div className="d-flex">
                          <span className="font-weight-bold mr-4">QTY : </span>
                          <button className="qty-btn" onClick={handleDecrement}>
                            -
                          </button>
                          <input
                            className="qtyp mt-0 text-center"
                            type="text"
                            value={count}
                            onChange={onChange}
                            onBlur={(e) => {
                              if (e.target.value <= selected?.stock) {
                                setCount(e.target.value);
                              } else {
                                setCount(1);
                                toast.info("Quantity limit exceeded");
                              }
                            }}
                          />
                          <button className="qty-btn" onClick={handleIncrement}>
                            +
                          </button>
                        </div>
                      )}
                      {/* <div className="col-xs-6" style={{ marginLeft: "13px" }}>
                      <div className="product_quantity">
                        {" "}
                        <span>QTY: </span>{" "}
                        <input
                          id="quantity_input"
                          type="text"
                          value={count}
                          onChange={onChange}
                          style={{ width: "60%", fontWeight: "bolder" }}
                        />
                        <div className="quantity_buttons">
                          <div
                            onClick={handleIncrement}
                            id="quantity_inc_button"
                            className="quantity_inc quantity_control"
                          >
                            <i className="fas fa-chevron-up"></i>
                          </div>
                          <div
                            onClick={handleDecrement}
                            id="quantity_dec_button"
                            className="quantity_dec quantity_control"
                          >
                            <i className="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      {singleRetailProducts?.expiryStatus === 0 ? (
                        <div className="col-xs-6 mt-4">
                          {" "}
                          <button
                            disabled={selected?.stock == 0}
                            onClick={() => {
                              if (!localStorage.getItem("token")) {
                                setModalVisible(true);
                              } else if (
                                ["ROLE_RETAILER", "ROLE_WHOLESALER"].includes(
                                  localStorage.getItem("auth")
                                ) ||
                                ([
                                  "ROLE_CEO",
                                  "ROLE_MANAGER",
                                  "ROLE_RECEPTIONIST",
                                  "ROLE_MARKETING_MANAGER",
                                ].includes(localStorage.getItem("auth")) &&
                                  location?.state === undefined)
                              ) {
                                toast.info("Login with user account.");
                              } else if (count > selected?.stock) {
                                toast.info("Quantity reset due to low stock");
                              } else {
                                dispatch(
                                  postSingleRetailCart({
                                    colorId: selected?.colorId,
                                    quantity: count,
                                    specificationId: selected?.specificationId,
                                    userId: customOrder
                                      ? customOrder.userId
                                      : localStorage.getItem("userId"),
                                    productName: selected?.name,
                                  })
                                );
                              }
                            }}
                            type="button"
                            className="btn btn-primary shop-button"
                          >
                            <i className="fa fa-shopping-cart"></i>
                            <span className="p-1">Add to Cart</span>
                          </button>{" "}
                          {/* <Link
                        to={{
                          pathname: selected &&
                           "/order-now",
                          state: {
                            itemName: selected?.name,
                            colorName: selected?.colorId,
                            quantity: count,
                            specificationId: selected?.specificationId,
                            rate: selected?.rate,
                            discount: selected?.discount,
                          },
                        }}
                      > */}
                          <button
                            disabled={selected?.stock == 0}
                            onClick={() => {
                              if (!localStorage.getItem("token")) {
                                setModalVisible(true);
                              } else if (
                                ["ROLE_RETAILER", "ROLE_WHOLESALER"].includes(
                                  localStorage.getItem("auth")
                                ) ||
                                ([
                                  "ROLE_CEO",
                                  "ROLE_MANAGER",
                                  "ROLE_RECEPTIONIST",
                                  "ROLE_MARKETING_MANAGER",
                                ].includes(localStorage.getItem("auth")) &&
                                  location?.state === undefined)
                              ) {
                                toast.info("Login with user account.");
                              } else {
                                history.push("/checkout-user", [
                                  {
                                    // cartId: item.id,
                                    colorId: selected?.colorId,
                                    orderQuantity: count,
                                    specificationId: selected?.specificationId,
                                    name: selected?.name,
                                    price: selected?.rate,
                                    discount: selected?.discount,
                                    discountPrice:
                                      selected?.rate -
                                      (selected?.rate * selected?.discount) /
                                        100,
                                    discountedTotalPrice:
                                      (selected?.rate -
                                        (selected?.rate * selected?.discount) /
                                          100) *
                                      count,
                                  },
                                ]);
                                // history.push("/order-now-user", {
                                //   itemName: selected?.name,
                                //   colorName: selected?.colorId,
                                //   quantity: count,
                                //   specificationId: selected?.specificationId,
                                //   rate: selected?.rate,
                                //   discount: selected?.discount,
                                // });
                              }
                            }}
                            type="button"
                            className="btn btn-success shop-button"
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>{" "}
                            Buy Now
                          </button>
                          {/* </Link> */}
                          <div
                            className="product_fav bg-info"
                            onClick={async () => {
                              if (!localStorage.getItem("token")) {
                                setModalVisible(true);
                              } else if (
                                ["ROLE_RETAILER", "ROLE_WHOLESALER"].includes(
                                  localStorage.getItem("auth")
                                ) ||
                                ([
                                  "ROLE_CEO",
                                  "ROLE_MANAGER",
                                  "ROLE_RECEPTIONIST",
                                  "ROLE_MARKETING_MANAGER",
                                ].includes(localStorage.getItem("auth")) &&
                                  location?.state === undefined)
                              ) {
                                toast.info("Login with user account.");
                              } else {
                                if (wishlist?.status === "404") {
                                  await dispatch(
                                    addToWishlist({
                                      userId: +localStorage.getItem("userId"),
                                      productId: id,
                                    })
                                  );
                                  toast.success("Added to Wishlist");
                                } else {
                                  await dispatch(
                                    removeFromWishlist({
                                      userId: +localStorage.getItem("userId"),
                                      id: +wishlist.data.id,
                                      productId: +id,
                                    })
                                  );
                                  toast.error("Removed from Wishlist");
                                }
                              }
                            }}
                          >
                            {wishlist?.status === "200" ? (
                              <i
                                className="fas fa-heart"
                                style={{
                                  color: "red",
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fas fa-heart"
                                style={{ color: "pink" }}
                              ></i>
                            )}
                          </div>
                          {selected?.stock === 0 && (
                            <h4 className="mt-4 ml-5" style={{ color: "red" }}>
                              OUT OF STOCK
                            </h4>
                          )}
                        </div>
                      ) : (
                        <h4 className="text-info mt-2 text-capitalize">
                          Sorry, the product has expired
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="row row-underline">
            <div className="col-md-6">
              {" "}
              <span className=" deal-text">Combo Offers</span>{" "}
            </div>
            <div className="col-md-6">
              {" "}
              <a href="#" data-abc="true">
                {" "}
                <span className="ml-auto view-all"></span>{" "}
              </a>{" "}
            </div>
          </div> 
          <div className="row">
            <div className="col-md-5">
              <div className="row padding-2">
                <div className="col-md-5 padding-0">
                  <div className="bbb_combo">
                    <div className="bbb_combo_image">
                      <img
                        className="bbb_combo_image"
                        src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560924153/alcatel-smartphones-einsteiger-mittelklasse-neu-3m.jpg"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                      {" "}
                      <strike style={{ color: "red" }}>
                        {" "}
                        <span className="fs-10" style={{ color: "black" }}>
                          ₹ 32,000<span> </span>
                        </span>
                      </strike>{" "}
                      <span className="ml-auto">
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                      </span>{" "}
                    </div>
                    <div
                      className="d-flex flex-row justify-content-start"
                      style={{ marginBottom: "13px" }}
                    >
                      {" "}
                      <span style={{ marginTop: "-4px" }}>₹30,000</span>{" "}
                      <span className="ml-auto fs-10">23 Reviews</span>{" "}
                    </div>{" "}
                    <span>Acer laptop with 10GB RAM + 500 GB Hard Disk</span>
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  {" "}
                  <span className="step__">+</span>{" "}
                </div>
                <div className="col-md-5 padding-0">
                  <div className="bbb_combo">
                    <div className="bbb_combo_image">
                      <img
                        className="bbb_combo_image"
                        src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560924153/alcatel-smartphones-einsteiger-mittelklasse-neu-3m.jpg"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                      {" "}
                      <strike style={{ color: "red" }}>
                        {" "}
                        <span className="fs-10" style={{ color: "black" }}>
                          ₹ 32,000<span> </span>
                        </span>
                      </strike>{" "}
                      <span className="ml-auto">
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                      </span>{" "}
                    </div>
                    <div
                      className="d-flex flex-row justify-content-start"
                      style={{ marginBottom: "13px" }}
                    >
                      {" "}
                      <span style={{ marginTop: "-4px" }}>₹30,000</span>{" "}
                      <span className="ml-auto fs-10">23 Reviews</span>{" "}
                    </div>{" "}
                    <span>Acer laptop with 10GB RAM + 500 GB Hard Disk</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12" style={{ marginLeft: "36px" }}>
                  <div className="boxo-pricing-items">
                    <div className="combo-pricing-item">
                      {" "}
                      <span className="items_text">1 Item</span>{" "}
                      <span className="combo_item_price">₹13,200</span>{" "}
                    </div>
                    <div className="combo-plus">
                      {" "}
                      <span className="plus-sign">+</span>{" "}
                    </div>
                    <div className="combo-pricing-item">
                      {" "}
                      <span className="items_text">1 Add-on</span>{" "}
                      <span className="combo_item_price">₹500</span>{" "}
                    </div>
                    <div className="combo-plus">
                      {" "}
                      <span className="plus-sign">=</span>{" "}
                    </div>
                    <div className="combo-pricing-item">
                      {" "}
                      <span className="items_text">Total</span>{" "}
                      <span className="combo_item_price">₹13,700</span>{" "}
                    </div>
                    <div className="add-both-cart-button">
                      {" "}
                      <button type="button" className="btn btn-primary shop-button">
                        Add to Cart
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 text-center">
              {" "}
              <span className="vertical-line"></span>{" "}
            </div>
            <div className="col-md-5" style={{ marginLeft: "-27px" }}>
              <div className="row padding-2">
                <div className="col-md-5 padding-0">
                  <div className="bbb_combo">
                    <div className="bbb_combo_image">
                      <img
                        className="bbb_combo_image"
                        src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560924153/alcatel-smartphones-einsteiger-mittelklasse-neu-3m.jpg"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                      {" "}
                      <strike style={{ color: "red" }}>
                        {" "}
                        <span className="fs-10" style={{ color: "black" }}>
                          ₹ 32,000<span> </span>
                        </span>
                      </strike>{" "}
                      <span className="ml-auto">
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                      </span>{" "}
                    </div>
                    <div
                      className="d-flex flex-row justify-content-start"
                      style={{ marginBottom: "13px" }}
                    >
                      {" "}
                      <span style={{ marginTop: "-4px" }}>₹30,000</span>{" "}
                      <span className="ml-auto fs-10">23 Reviews</span>{" "}
                    </div>{" "}
                    <span>Acer laptop with 10GB RAM + 500 GB Hard Disk</span>
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  {" "}
                  <span className="step__">+</span>{" "}
                </div>
                <div className="col-md-5 padding-0">
                  <div className="bbb_combo">
                    <div className="bbb_combo_image">
                      <img
                        className="bbb_combo_image"
                        src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560924153/alcatel-smartphones-einsteiger-mittelklasse-neu-3m.jpg"
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                      {" "}
                      <strike style={{ color: "red" }}>
                        {" "}
                        <span className="fs-10" style={{ color: "black" }}>
                          ₹ 32,000<span> </span>
                        </span>
                      </strike>{" "}
                      <span className="ml-auto">
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                        <i className="fa fa-star p-rating"></i>
                      </span>{" "}
                    </div>
                    <div
                      className="d-flex flex-row justify-content-start"
                      style={{ marginBottom: "13px" }}
                    >
                      {" "}
                      <span style={{ marginTop: "-4px" }}>₹30,000</span>{" "}
                      <span className="ml-auto fs-10">23 Reviews</span>{" "}
                    </div>{" "}
                    <span>Acer laptop with 10GB RAM + 500 GB Hard Disk</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12" style={{ marginLeft: "36px" }}>
                  <div className="boxo-pricing-items">
                    <div className="combo-pricing-item">
                      {" "}
                      <span className="items_text">1 Item</span>{" "}
                      <span className="combo_item_price">₹13,200</span>{" "}
                    </div>
                    <div className="combo-plus">
                      {" "}
                      <span className="plus-sign">+</span>{" "}
                    </div>
                    <div className="combo-pricing-item">
                      {" "}
                      <span className="items_text">1 Add-on</span>{" "}
                      <span className="combo_item_price">₹500</span>{" "}
                    </div>
                    <div className="combo-plus">
                      {" "}
                      <span className="plus-sign">=</span>{" "}
                    </div>
                    <div className="combo-pricing-item">
                      {" "}
                      <span className="items_text">Total</span>{" "}
                      <span className="combo_item_price">₹13,700</span>{" "}
                    </div>
                    <div className="add-both-cart-button">
                      {" "}
                      <button type="button" className="btn btn-primary shop-button">
                        Add to Cart
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
              <div className="mt-4">
                <h4 className="pr-4 mb-2">Description</h4>
                <div
                  style={{
                    wordBreak: "break-all",
                    textAlign: "justify",
                    padding: "0 5px",
                    fontWeight: 400,
                  }}
                >
                  {ReactHtmlParser(singleRetailProducts?.productDescription)}
                </div>
              </div>
              <div className="row row-underline">
                <div className="col-md-6">
                  {" "}
                  <span className="deal-text">Specifications</span>{" "}
                </div>
                <div className="col-md-6">
                  {" "}
                  <a href="#" data-abc="true">
                    {" "}
                    <span className="ml-auto view-all"></span>{" "}
                  </a>{" "}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="col-md-12">
                    <tbody>
                      <tr className="row mt-10">
                        <td className="col-md-4">
                          <span className="p_specification">
                            Product Height :
                          </span>{" "}
                        </td>
                        {singleRetailProducts?.specification
                          ?.filter((i) => i.id === selected?.specificationId)
                          ?.map((i) => (
                            <td className="col-md-8" key={i.id}>
                              <ul>
                                <li>
                                  {i.productHeight === null
                                    ? "________"
                                    : i.productHeight + " cm"}
                                </li>
                              </ul>
                            </td>
                          ))}
                      </tr>
                      <tr className="row mt-10">
                        <td className="col-md-4">
                          <span className="p_specification">
                            Product Thickness :
                          </span>{" "}
                        </td>
                        {singleRetailProducts?.specification
                          ?.filter((i) => i.id === selected?.specificationId)
                          ?.map((i) => (
                            <td className="col-md-8">
                              <ul>
                                <li>
                                  {" "}
                                  {i.productThickness === null
                                    ? "________"
                                    : i.productThickness + " cm"}
                                </li>
                              </ul>
                            </td>
                          ))}
                      </tr>
                      <tr className="row mt-10">
                        <td className="col-md-4">
                          <span className="p_specification">
                            Product Length :
                          </span>{" "}
                        </td>
                        {singleRetailProducts?.specification
                          ?.filter((i) => i.id === selected?.specificationId)
                          ?.map((i) => (
                            <td className="col-md-8">
                              <ul>
                                <li>
                                  {" "}
                                  {i.productLength === null
                                    ? "________"
                                    : i.productLength + " cm"}
                                </li>
                              </ul>
                            </td>
                          ))}
                      </tr>
                      <tr className="row mt-10">
                        <td className="col-md-4">
                          <span className="p_specification">
                            Product Weight :
                          </span>{" "}
                        </td>
                        {singleRetailProducts?.specification
                          ?.filter((i) => i.id === selected?.specificationId)
                          ?.map((i) => (
                            <td className="col-md-8">
                              <ul>
                                <li>
                                  {i.product_weight === null
                                    ? "________"
                                    : i.product_weight + " kg"}
                                </li>
                              </ul>
                            </td>
                          ))}
                      </tr>
                      {/* <tr className="row mt-10">
                        <td className="col-md-4">
                          <span className="p_specification">
                            Product Quantity :
                          </span>{" "}
                        </td>
                        {singleRetailProducts?.specification
                          ?.filter((i) => i.id === selected?.specificationId)
                          ?.map((i) => (
                            <td className="col-md-8">
                              <ul>
                                <li>
                                  {i.productQuantity === null
                                    ? "________"
                                    : i.productQuantity}
                                </li>
                              </ul>
                            </td>
                          ))}
                      </tr> */}
                      {/* <tr className="row mt-10">
                      <td className="col-md-4">
                        <span className="p_specification">Suitable for :</span>{" "}
                      </td>
                      <td className="col-md-8">
                        <ul>
                          <li>Processing & Multitasking</li>
                        </ul>
                      </td>
                    </tr>
                    <tr className="row mt-10">
                      <td className="col-md-4">
                        <span className="p_specification">
                          Processor Brand :
                        </span>{" "}
                      </td>
                      <td className="col-md-8">
                        <ul>
                          <li>Intel</li>
                        </ul>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*  */}
            {/* <div className="row"> */}
            {/* <div
                className="col-md-8 col-md-offset-1 col-sm-12 col-xs-12 mt-4"
                
              > */}
            {/* <h5 style={{ textAlign: "start" }}>Submit a review</h5>
                <form
                  method="post"
                  className="well padding-bottom-10"
                  onSubmit="return false;"
                > 
                  <textarea
                    rows="2"
                    className="form-control"
                    placeholder="Write a review"
                  ></textarea>
                  <div className="margin-top-10">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary pull-right"
                    >
                      Submit Review
                    </button>
                  </div>
                </form>*/}
            {/* <div className="chat-body no-padding profile-message"> */}
            {/* <ul className="messagebox-product"> */}
            <ul className="row">
              {singleRetailProducts?.totalReview != 0 && (
                <span className="deal-text">Reviews</span>
              )}
              {singleRetailProducts?.review?.map((r, index) => (
                <li className="message col-md-6 col-lg-4" key={index}>
                  <div className="message-header">
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${r?.reviewUserId}/${r?.photo}`}
                        className="online"
                        width="60"
                        alt={r?.firstName}
                        style={{
                          borderRadius: 4,
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span className="text-capitalize font-weight-bold">
                          {r?.firstName} {r?.lastName}
                        </span>
                        {/* <small className="text-muted pull-right ultra-light">
                              {" "}
                              Posted 1 year ago{" "}
                            </small> */}
                      </div>
                    </div>
                    <span className="pull-right">
                      <RatingView
                        ratingValue={singleRetailProducts?.totalReview}
                        size={30}
                      />
                    </span>
                  </div>
                  <div className="message-text text-capitalize">
                    {r?.review}
                  </div>
                  {/* <ul className="list-inline font-xs">
                        <li>
                          <a href="javascript:void(0);" className="text-info">
                            <i className="fa fa-thumbs-up"></i> This was
                            helpful (22)
                          </a>
                        </li>
                      </ul> */}
                </li>
              ))}
            </ul>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner />
          </div>
        )}
        <UserFooter />
      </div>
      {/* main content end */}
      <Modal
        show={modalVisible}
        centered
        size="lg"
        onHide={() => setModalVisible(false)}
      >
        <Modal.Body>
          <UserLogin
            modal="true"
            modalVisible={setModalVisible}
            style={{ zIndex: 999999 }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalVisible(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SingleProductDetail;
